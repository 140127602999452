// import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App.tsx'
import './index.css'
import './i18n'
import { env } from './env.ts'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { PostHogProvider } from 'posthog-js/react'

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://88d1283892194cf79562b51de98102ff@o1364239.ingest.sentry.io/4505302415769600',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `alphabait@${import.meta.env.VITE_PACKAGE_VERSION}`,
  })
}
import { AlphaApolloClient } from './alpha-apollo.tsx'
import { BrowserRouter } from 'react-router-dom'

const options = {
  api_host: env.VITE_PUBLIC_POSTHOG_HOST,
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <RecoilRoot>
      <Auth0Provider
        domain={env.VITE_AUTH0_DOMAIN}
        clientId={env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: env.VITE_AUTH0_REDIRECT_URL,
          scope: 'openid profile email',
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <AlphaApolloClient>
          <PostHogProvider
            apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
            options={options}
          >
            <App />
          </PostHogProvider>
        </AlphaApolloClient>
        ,
      </Auth0Provider>
    </RecoilRoot>
  </BrowserRouter>,
  // </React.StrictMode>,
)
