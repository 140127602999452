import { gql } from '@apollo/client'

export default gql`
  query IncentivesStore($pagination: PaginationInput!, $filters: FiltersInput) {
    incentivesStore(pagination: $pagination, filters: $filters) {
      pagination {
        page
        pageSize
        total
      }
      incentives {
        totalClaimedIncentives
        featured
        incentive {
          id
          campaignId
          customerId
          name
          price
          description
          isAvailable
          type
          picture
          skuCode
          isActive
          categoryId
          categories {
            id
            incentiveCategory {
              id
              name
            }
          }
          isAttachedToLotteryCampaign
          files {
            id
            s3Key
            url
            aggregateId
            fileType
            fileVariant
          }
        }
      }
    }
  }
`
