import { gql } from '@apollo/client'

export default gql`
  query cc {
    customers {
      id
      name
      integrations {
        id
        type
      }
    }
  }
`
