import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupWithDescriptionInput } from './custom-types'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Controller } from 'react-hook-form'

type RadioGroupWithDescriptionType = {
  name: string
  label: string
  description?: string
}

export default function RadioGroupWithDescription({
  field,
  formProps,
}: {
  field: RadioGroupWithDescriptionInput
  formProps: FormProps
}) {
  const [isSelected, setIsSelected] = useState<string>()
  useEffect(() => {
    setIsSelected(field.value)
  }, [field.value])

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value } }) => (
        <div className="-space-y-px rounded-md">
          {field.options &&
            field.options.map(
              (category: RadioGroupWithDescriptionType, index) => {
                return (
                  <label
                    key={category.name}
                    htmlFor={category.name}
                    onClick={() => setIsSelected(category.name)}
                    className={cx(
                      index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      index === field.options.length - 1
                        ? 'rounded-bl-md rounded-br-md'
                        : '',
                      isSelected === category.name
                        ? ' border-marine-30 bg-gray-8'
                        : 'border-ui1',
                      'relative flex cursor-pointer border p-4 focus:outline-none',
                      field.uiOptions?.disabled && 'pointer-events-none',
                    )}
                  >
                    <div className="w-6">
                      <input
                        type="radio"
                        value={category.name}
                        checked={value === category.name}
                        onChange={(e) => {
                          onChange(e.target.value)
                          setIsSelected(e.target.value)
                        }}
                        className="w-6 h-6"
                        id={category.name}
                        data-testid={`${field.uiOptions?.testId}-${category.name}`}
                        disabled={field.uiOptions?.disabled}
                      />
                    </div>
                    <div className="mx-2 align-top grid">
                      <p
                        className={
                          isSelected === category.name
                            ? 'self-stretch text-sm font-bold text-black-1'
                            : 'text-sm leading-5 font-medium'
                        }
                      >
                        {category.label}
                      </p>
                      {category.description && (
                        <p className="self-stretch text-gray-3 text-sm leading-[1.1875rem]">
                          {category.description}
                        </p>
                      )}
                    </div>
                  </label>
                )
              },
            )}
        </div>
      )}
    />
  )
}
