import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
import {
  Customer,
  IncentiveCategory,
  IncentiveStore,
  UpdateIncentiveItemInput,
} from '@/gql'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import INCENTIVES_STORE_QUERY from '../graphql/incentive/queries/incentives-store'
import UPDATE_INCENTIVE_MUTATION from '@/graphql/incentive/mutations/update'
import USER_QUERY from '@/graphql/user'
import CUSTOMERS_QUERY from '@/graphql/customer'
import CATEGORIES_QUERY from '@/graphql/incentive/queries/categories'
import { displayS3File } from '@/utils/s3'
import { Switch } from '@/components/switch'
import { Button } from '@/components/button'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ErrorModal from '@/components/error-modal'
import { Plus } from '@phosphor-icons/react'
import Tooltip from '@/components/tooltip'
import ReactSelect, { SingleValue, StylesConfig } from 'react-select'
import { useDebouncedCallback } from 'use-debounce'

enum Status {
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  UPCOMING = 'upcoming',
  DRAFT = 'draft',
}

export type CampaignTableItem = {
  id: string
  name: string
  type: string
  isDraft: boolean
  startDate: string
  endDate: string
  status: Status
  claimedGifts: string
  updatedAt: string
  completedStep: number
  printFileDownloadLink: string
  printFilePassword: string
}

export default function IncentivesStore() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [incentive, setIncentive] = useState<UpdateIncentiveItemInput>()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<{
    title?: string
    text?: string
  }>()

  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  )
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  )
  const incentivesPerPage = 10
  const [localSearch, setLocalSearch] = useState('')

  const [
    getAsyncIncentivesStore,
    {
      data: incentives,
      loading: loadingIncentivesStore,
      error: errorIncentivesStore,
    },
  ] = useLazyQuery(INCENTIVES_STORE_QUERY, {
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
  })

  const { data: customersData, loading: customersLoading } = useQuery(
    CUSTOMERS_QUERY,
    {},
  )

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    CATEGORIES_QUERY,
    {},
  )

  useEffect(() => {
    getAsyncIncentivesStore({
      variables: {
        pagination: {
          page: currentPage,
          pageSize: incentivesPerPage,
        },
        filters: {
          search: searchQuery,
          customerId: selectedCustomerId,
          categoryId: selectedCategoryId,
        },
      },
    })
  }, [
    currentPage,
    searchQuery,
    selectedCustomerId,
    selectedCategoryId,
    getAsyncIncentivesStore,
  ])

  const incentivesList = incentives?.incentivesStore.incentives || []
  const totalIncentives = incentives?.incentivesStore?.pagination.total || 0
  const totalPages = Math.ceil(totalIncentives / incentivesPerPage)
  const startIndex = (currentPage - 1) * incentivesPerPage + 1
  const endIndex = Math.min(currentPage * incentivesPerPage, totalIncentives)

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (incentivesList.length === incentivesPerPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const debouncedSearch = useDebouncedCallback((text: string) => {
    setSearchQuery(text)
    setCurrentPage(1)
  }, 1000)

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalSearch(e.target.value)
      debouncedSearch(e.target.value)
    },
    [debouncedSearch],
  )

  const handleCustomerChange = useCallback(
    (newValue: SingleValue<{ value: string; label: string }>) => {
      setSelectedCustomerId(newValue ? newValue.value : null)
      setCurrentPage(1)
    },
    [],
  )

  const handleCategoryChange = useCallback(
    (newValue: SingleValue<{ value: string; label: string }>) => {
      setSelectedCategoryId(newValue ? newValue.value : null)
      setCurrentPage(1)
    },
    [],
  )

  const customerOptions = customersData
    ? [
        { value: '', label: 'All Customers' },
        ...customersData.customers.map((customer: Customer) => ({
          value: customer.id,
          label: customer.name,
        })),
      ]
    : []

  const categoryOptions = categoriesData
    ? [
        { value: '', label: 'All Categories' },
        ...categoriesData.incentiveCategories.map(
          (category: IncentiveCategory) => ({
            value: category.id,
            label: t(`incentive_categories.${category.name}`),
          }),
        ),
      ]
    : []

  const {
    data: user,
    loading: loadingUser,
    error: errorUser,
  } = useQuery(USER_QUERY, {})

  const [updateMutation] = useMutation(UPDATE_INCENTIVE_MUTATION, {
    refetchQueries: () => [{ query: INCENTIVES_STORE_QUERY, variables: {} }],
    onCompleted: () => {
      setShowErrorModal(false)
    },
  })

  const rowContentClassName = 'px-5 py-4 text-sm text-gray-1 items-center '
  const tableHeadersClassName =
    'whitespace-nowrap px-5 py-4 text-left text-sm font-normal text-gray-1'

  const sharedSelectStyles: StylesConfig = {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (provided) => ({
      ...provided,
      width: '165px',
      height: '40px',
    }),
  }

  if (
    loadingIncentivesStore ||
    loadingUser ||
    customersLoading ||
    categoriesLoading
  )
    return <div>{t('loading')}</div>
  if (errorIncentivesStore || errorUser) return <div>{t('error')}</div>

  return (
    <div className="bg-ui1 p-8 h-full">
      <div
        className={cx(
          !incentives?.incentivesStore.length ? undefined : 'overflow-scroll',
          'p-8 sm:rounded-lg bg-white',
        )}
      >
        <h1 className="font-bold text-4xl pb-10 leading-none mb-0">
          {t('incentives_store.title')}
        </h1>
        <div className="mb-4 flex flex-col gap-2 md:flex-row md:justify-between">
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <input
              type="text"
              placeholder="Search incentives"
              value={localSearch}
              onChange={handleSearchChange}
              className="border border-gray-300 rounded-md p-2 h-10"
            />
            <ReactSelect
              options={customerOptions}
              placeholder="Customer"
              isSearchable={false}
              value={customerOptions.find(
                (option) => option.value === selectedCustomerId,
              )}
              onChange={handleCustomerChange}
              styles={sharedSelectStyles}
              defaultValue={customerOptions[0]}
            ></ReactSelect>
            <ReactSelect
              options={categoryOptions}
              placeholder="Category"
              isSearchable={false}
              value={categoryOptions.find(
                (option) => option.value === selectedCategoryId,
              )}
              onChange={handleCategoryChange}
              styles={sharedSelectStyles}
              defaultValue={categoryOptions[0]}
            ></ReactSelect>
          </div>
          <div className="flex flex-col md:flex-row items-center">
            <Button
              label={t('incentives_store.new_incentive')}
              onClick={() => navigate('/incentives/create')}
              IconLeft={Plus}
              className="border border-primary text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus-visible:outline focus:ring-primary"
              size="small"
            />
          </div>
        </div>
        {incentivesList.length ? (
          <div className="border sm:rounded-lg ">
            <table className="w-full table-auto">
              <thead className="">
                <tr className="border-b h-14">
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.featured')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.product_info')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.price')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.category')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.description')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.claimed')}
                  </th>
                  <th scope="col" className={tableHeadersClassName}>
                    {t('incentives_store.disabled_active')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {incentivesList.map(
                  (incentiveWithClaimedStatus: IncentiveStore) => {
                    const { incentive } = incentiveWithClaimedStatus
                    return (
                      <tr
                        onClick={() => {
                          navigate(`/incentives/update/${incentive?.id}`)
                        }}
                        className="border-b h-14 cursor-pointer"
                        key={incentive?.id}
                      >
                        <td className={cx(rowContentClassName, 'w-24')}>
                          <Tooltip
                            child={
                              <div className="flex items-center gap-x-2">
                                <div
                                  className={cx(
                                    incentive?.isActive
                                      ? 'bg-primary'
                                      : 'bg-ui1',
                                    'w-2 h-2 rounded-full',
                                  )}
                                ></div>
                                {incentiveWithClaimedStatus.featured}
                              </div>
                            }
                            text={t('incentives_store.featured_tooltip', {
                              numberOfCampaigns:
                                incentiveWithClaimedStatus.featured,
                            })}
                          />
                        </td>
                        <td
                          className={cx(
                            rowContentClassName,
                            'flex items-center gap-2 w-64',
                          )}
                        >
                          <div className="w-20">
                            <img
                              className="rounded-md max-w-20 max-h-14"
                              src={
                                incentive?.picture
                                  ? displayS3File(incentive.picture)
                                  : ''
                              }
                            />
                          </div>
                          <div className="w-48">
                            <p className="font-bold text-black-1">
                              {incentive?.name}
                            </p>
                            <p className="font-normal text-gray-2">
                              {incentive?.skuCode}
                            </p>
                          </div>
                        </td>
                        <td className={cx(rowContentClassName, 'w-40')}>
                          <span className="font-bold">
                            &#8364; {incentive?.price}
                          </span>{' '}
                          /{t('incentives_store.pcs')}
                        </td>
                        <td className={cx(rowContentClassName, 'w-40')}>
                          {incentive?.categories?.[0]?.incentiveCategory?.name
                            ? t(
                                `incentive_categories.${incentive?.categories?.[0]?.incentiveCategory?.name}`,
                              )
                            : undefined}
                        </td>
                        <td className={cx(rowContentClassName, 'w-56')}>
                          {incentive?.description}
                        </td>
                        <td className={cx(rowContentClassName, 'w-40')}>
                          {incentiveWithClaimedStatus?.totalClaimedIncentives}
                        </td>
                        <td
                          className={cx(
                            rowContentClassName,
                            'align-middle w-24 text-center',
                          )}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <Switch
                            defaultChecked={incentive?.isActive ?? false}
                            className="data-[state=checked]:bg-green-400"
                            disabled={
                              user?.user?.role === 'Superadmin'
                                ? false
                                : !(
                                    user?.user?.customerId ===
                                    incentive?.customerId
                                  )
                            }
                            onCheckedChange={(toggle) => {
                              const numberOfCampaigns =
                                incentiveWithClaimedStatus?.featured ?? 0
                              const incentiveItem = {
                                id: incentive?.id ?? '',
                                name: incentive?.name ?? '',
                                isActive: toggle,
                                price: incentive?.price ?? 0,
                                picture: incentive?.picture ?? '',
                                description: incentive?.description ?? '',
                                categoryId: incentive?.categoryId ?? '',
                                type: incentive?.type ?? '',
                                campaignId: incentive?.campaignId ?? null,
                              }
                              numberOfCampaigns > 0 && toggle == false
                                ? (setShowErrorModal(true),
                                  setErrorMessage({
                                    title: t(
                                      'incentive_errors_modal.update_error_modal_title',
                                      {
                                        numberOfCampaigns,
                                      },
                                    ),
                                    text: t(
                                      `incentive_errors_modal.update_incentive_item_is_used`,
                                      {
                                        numberOfCampaigns,
                                      },
                                    ),
                                  }),
                                  incentiveItem && setIncentive(incentiveItem))
                                : updateMutation({
                                    variables: {
                                      incentiveItem: incentiveItem,
                                    },
                                  })
                            }}
                          />
                        </td>
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full text-center h-96 mt-28">
            <p>{t('incentives_store.no_incentives')}</p>
            <p>{t('incentives_store.please_add')}</p>
          </div>
        )}
        <div className="mt-4 flex justify-between">
          <div className="flex items-center">
            Page {currentPage} of {totalPages} ({startIndex}-{endIndex} from{' '}
            {totalIncentives} row(s) shown)
          </div>
          <div className="flex gap-2">
            <Button
              label={t('incentives_store.previous')}
              className="border border-gray-200"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              size="small"
            ></Button>
            <Button
              label={t('incentives_store.next')}
              className="border border-gray-200 h-10"
              onClick={handleNextPage}
              disabled={incentivesList.length < incentivesPerPage}
              size="small"
            ></Button>
          </div>
        </div>
      </div>
      {showErrorModal &&
        createPortal(
          <ErrorModal
            hasOnSubmit={true}
            title={errorMessage?.title ?? ''}
            error={errorMessage?.text ?? ''}
            onClick={() => {
              setErrorMessage({})
              setIncentive(undefined)

              // Re-fetch incentives store to update the UI, because refetch() is not working
              getAsyncIncentivesStore()
              setShowErrorModal(false)
            }}
            onSubmit={() => {
              updateMutation({
                variables: {
                  incentiveItem: incentive,
                },
              })
            }}
            submitButtonText={t('disable')}
          />,
          document.body,
        )}
    </div>
  )
}
