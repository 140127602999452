import { FormProps } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@/components/ui/date-picker'
import { Combobox } from '../ui/combobox'
import { DateTimeInput } from './custom-types'
import { format } from 'date-fns'

export default function CustomDateTime({
  field,
  formProps,
}: {
  field: DateTimeInput
  formProps: FormProps
}) {
  const hours = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? `0${i}` : i.toString(),
  )
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i < 10 ? `0${i}` : i.toString(),
  )

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value } }) => {
        const dateValue =
          value ?? formProps.formValues[field.name] ?? new Date().toISOString()
        const localDate = format(new Date(dateValue), 'yyyy-MM-dd HH:mm')

        const [d, t] = localDate.split(' ')
        const [h, m] = t.split(':')

        const handleDateChange = (newDate: string) => {
          const combinedDateTime = `${newDate}T${h}:${m}:00`
          onChange(new Date(combinedDateTime).toISOString())
        }

        const handleHourChange = (newHour: string) => {
          const combinedDateTime = `${d}T${newHour}:${m}:00`
          onChange(new Date(combinedDateTime).toISOString())
        }

        const handleMinuteChange = (newMinute: string) => {
          const combinedDateTime = `${d}T${h}:${newMinute}:00`
          onChange(new Date(combinedDateTime).toISOString())
        }

        return (
          <div>
            <div data-testid={field.name} className="flex gap-2">
              <div className={field.uiOptions?.className}>
                <DatePicker
                  value={d}
                  setValue={(newDate) =>
                    handleDateChange(newDate.substring(0, 10))
                  }
                />
              </div>
              <div className="flex-col">
                <div className="flex gap-2">
                  <Combobox
                    type="single"
                    value={h}
                    placeholder="Hour"
                    className="w-2 h-10"
                    setValue={(newHour) => handleHourChange(newHour || '00')}
                    options={hours.map((hour) => ({
                      value: hour,
                      label: hour,
                    }))}
                  />

                  <Combobox
                    type="single"
                    value={m}
                    placeholder="Minute"
                    className="w-2 h-10"
                    setValue={(newMinute) =>
                      handleMinuteChange(newMinute || '00')
                    }
                    options={minutes.map((minute) => ({
                      value: minute,
                      label: minute,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="my-auto py-1 text-sm text-gray-3">
              {format(new Date(dateValue), 'yyyy-MM-dd HH:mm')}(
              {Intl.DateTimeFormat().resolvedOptions().timeZone})
            </div>
          </div>
        )
      }}
    />
  )
}
