import { t } from 'i18next'

export const incentiveTypeOptions = (hasIntersolveIntegration: boolean) => {
  const options = [
    {
      name: 'unique',
      label: t('incentives.types.unique_digital'),
    },
    {
      name: 'universal',
      label: t('incentives.types.universal_digital'),
    },
    {
      name: 'physical',
      label: t('incentives.types.physical'),
    },
  ]

  if (hasIntersolveIntegration) {
    options.push({
      name: 'intersolve',
      label: t('incentives.types.intersolve'),
    })
  }

  return options
}
