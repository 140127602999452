import { gql } from '@apollo/client'

export default gql`
  query c($pagination: PaginationInput, $filters: CampaignFiltersInput) {
    campaigns(pagination: $pagination, filters: $filters) {
      pagination {
        page
        pageSize
        total
      }
      campaigns {
        id
        name
        typeOfCampaign
        companyName
        startDate
        endDate
        details
        referralCampaign
        isDraft
        postcardTemplate
        updatedAt
        completedStep
        printFilesLink
        printPassword
        campaignCategory
        customer {
          id
          name
        }
      }
    }
  }
`
