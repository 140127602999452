import { useQuery } from '@apollo/client'
import INTERSOLVE_SHOPS_QUERY from '@/graphql/incentive/queries/intersolve-shops'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useMemo } from 'react'

export default function IntersolveSettings(props: {
  value?: string | undefined
  onChange: (value: string) => void
  customerId?: string | undefined | null
  disabled?: boolean
}) {
  const { data, loading, error } = useQuery(INTERSOLVE_SHOPS_QUERY)

  const intersolveShops = useMemo(
    () =>
      data?.getIntersolveShops
        .filter(
          (shop: { customerId: string }) =>
            shop.customerId === props.customerId,
        )
        .map((shop: { id: string; name: string }) => ({
          value: shop.id,
          label: shop.name,
        })),
    [data?.getIntersolveShops, props.customerId],
  )

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error loading shops</div>

  if (!props.customerId) {
    return <div className="text-sm">Please select a customer first.</div>
  } else if (intersolveShops.length === 0) {
    return <div className="text-sm">No shops available for this customer.</div>
  }

  return (
    <Select
      onValueChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select a shop" />
      </SelectTrigger>
      <SelectContent className="dropdown-content">
        {intersolveShops.map((option: { label: string; value: string }) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
