import { z } from 'zod'

const envSchema = z.object({
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_REDIRECT_URL: z.string(),
  VITE_S3_URL: z.string(),
  VITE_PUBLIC_URL: z.string(),
  VITE_PUBLIC_POSTHOG_KEY: z.string().optional(),
  VITE_PUBLIC_POSTHOG_HOST: z.string().optional(),
})

export const env = envSchema.parse({
  VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_REDIRECT_URL: import.meta.env.VITE_AUTH0_REDIRECT_URL,
  VITE_S3_URL: import.meta.env.VITE_S3_URL,
  VITE_PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
  VITE_PUBLIC_POSTHOG_KEY: import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
  VITE_PUBLIC_POSTHOG_HOST: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
})
