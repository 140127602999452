import { useEffect } from 'react'
import DashboardCard from '@/components/dashboard-card'
import BarChart from '@/components/charts/bar-chart'
import LineChart from '@/components/charts/line-chart'
import DoughnutChart from '@/components/charts/doughnut-chart'
import StackedBarChart from '@/components/charts/happiness-check-chart'
import Select from 'react-select'
import CAMPAIGNS_QUERY from '../graphql/campaign/queries/list'
import END_USERS_PER_CAMPAIGN from '../graphql/dashboard/end-users-per-campaign'
import INCENTIVES_PER_CAMPAIGN from '../graphql/dashboard/incentives-per-campaign'
import CAMPAIGN_PERFORMANCE from '../graphql/dashboard/campaign-performance'
import CAMPAIGN_PERFORMANCE_OVER_TIME from '../graphql/dashboard/campaign-performance-over-time'
import HAPPINESS_CHECK_COUNT from '../graphql/dashboard/happiness-check'
import GET_VOUCHERS_INFO from '../graphql/dashboard/get-vouchers-info.ts'
import { useLazyQuery, useQuery } from '@apollo/client'

import { useNavigate, useParams } from 'react-router-dom'
import {
  CampaignPerformanceOverTimeByType,
  CampaignsResponse,
} from '../../../server/src/graphql'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { format } from 'date-fns'
import BarChartHorizontal from '@/components/charts/bar-chart-horizontal'

export default function Dashboard() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [getAsyncEndUsers, { data: endUsersData, loading: endUsersLoading }] =
    useLazyQuery(END_USERS_PER_CAMPAIGN, { fetchPolicy: 'network-only' })
  const [
    getAsyncIncentivesCount,
    { data: incentivesData, loading: incentivesLoading },
  ] = useLazyQuery(INCENTIVES_PER_CAMPAIGN, { fetchPolicy: 'network-only' })
  const [
    getAsyncCampaignPerformance,
    { data: campaignPerformance, loading: campaignPerformanceLoading },
  ] = useLazyQuery(CAMPAIGN_PERFORMANCE, { fetchPolicy: 'network-only' })
  const [
    getAsyncCampaignPerformanceOverTime,
    {
      data: campaignPerformanceOverTime,
      loading: campaignPerformanceOverTimeLoading,
    },
  ] = useLazyQuery<{
    campaignPerformanceOverTime: CampaignPerformanceOverTimeByType
  }>(CAMPAIGN_PERFORMANCE_OVER_TIME, { fetchPolicy: 'network-only' })
  const [
    getAsyncHappinessCheck,
    { data: happinessCheckCount, loading: happinessCheckLoading },
  ] = useLazyQuery(HAPPINESS_CHECK_COUNT, { fetchPolicy: 'network-only' })
  const [
    getAsyncVouchersInfo,
    { data: getVouchersInfo, loading: getVouchersInfoLoading },
  ] = useLazyQuery(GET_VOUCHERS_INFO, { fetchPolicy: 'network-only' })

  const { data, loading } = useQuery<{ campaigns: CampaignsResponse }>(
    CAMPAIGNS_QUERY,
    { fetchPolicy: 'network-only' },
  )

  const campaigns = data?.campaigns?.campaigns || []

  const campaignId =
    useParams().campaignId || (campaigns.length > 0 && campaigns[0]?.id)
  const transformedData =
    data &&
    campaigns?.map((d) => {
      return { label: d?.name, value: d?.id }
    })
  const isReferral =
    campaigns?.find((c) => c?.id === campaignId)?.referralCampaign ?? false
  const hasHappinessCheck =
    campaigns.find((c) => c?.id === campaignId)?.happinessCheck !== null

  const getVoucherStatistics = getVouchersInfo?.getVouchersInfo.map(
    (incentive: {
      incentiveItemId: string
      incentiveItemName: string
      voucherCodes: { id: string; isUsed: boolean }[]
    }) => {
      const usedVouchers = incentive.voucherCodes
        .filter((voucher) => voucher.isUsed)
        .length.toString()
      const totalVouchers = incentive.voucherCodes.length.toString()
      return {
        label: `${incentive.incentiveItemName} ${usedVouchers}/${totalVouchers}`,
        data: usedVouchers,
      }
    },
  )

  useEffect(() => {
    if (campaignId) {
      getAsyncEndUsers({
        variables: {
          campaignId: campaignId,
        },
      })
      getAsyncIncentivesCount({
        variables: {
          campaignId: campaignId,
          referral: false,
        },
      })
      getAsyncCampaignPerformance({
        variables: {
          campaignId: campaignId,
          referral: false,
        },
      })
      getAsyncCampaignPerformanceOverTime({
        variables: {
          campaignId: campaignId,
          referral: false,
        },
      })
      getAsyncHappinessCheck({
        variables: {
          campaignId: campaignId,
          referral: false,
        },
      })
      getAsyncVouchersInfo({
        variables: {
          campaignId: campaignId,
        },
      })
    }
  }, [campaignId])

  const selectedCampaign = campaigns.find((c) => c?.id === campaignId)
  const endUsersPerCampaign =
    selectedCampaign?.postcardTemplate?.['type'] === 'flyer'
      ? campaignPerformance?.campaignPerformance.general.find(
          (c: { action: string; count: number }) => c.action === 'reached',
        )?.count
      : endUsersData?.endUsersPerCampaign?.general.length || 0

  if (loading) return <div>Loading...</div>
  if (
    endUsersLoading ||
    incentivesLoading ||
    campaignPerformanceLoading ||
    campaignPerformanceOverTimeLoading ||
    happinessCheckLoading ||
    getVouchersInfoLoading
  )
    return <div>Loading...</div>

  return (
    <div className="p-10">
      <div className="flex justify-between items-center gap-4">
        <div className="flex gap-4 justify-start items-start">
          <h1 className="m-auto font-bold text-2xl leading-none">
            {t('dashboard_labels.dashboard')}
          </h1>
          {campaignId && (
            <span className="p-1 bg-green-60 bg-opacity-25 text-green-500 rounded">
              {selectedCampaign?.name}
            </span>
          )}
        </div>

        <Select
          defaultValue={transformedData?.find((c) => c.value === campaignId)}
          options={transformedData}
          onChange={(value) => value && navigate(`/dashboard/${value.value}`)}
          className="w-64"
        />
      </div>
      {campaignId && (
        <>
          <h1 className="pt-4 text-2xl border-b border-ui1">
            {t('dashboard_labels.general_campaign')}
          </h1>
          <div className="grid grid-cols-2 gap-6">
            {endUsersData?.endUsersPerCampaign?.general.length && (
              <DashboardCard
                title={t('dashboard_labels.campaign_overview')}
                description={t(
                  'dashboard_labels.since_the_beginning_of_the_campaign',
                )}
                children={
                  <BarChart
                    data={generalToChart(
                      campaignPerformance?.campaignPerformance?.general,
                      endUsersPerCampaign,
                    )}
                    type="campaign"
                  />
                }
              />
            )}
            {incentivesData?.incentivesPerCampaign?.general && (
              <DashboardCard
                title={t('dashboard_labels.requested_incentives')}
                description={t('dashboard_labels.by_type')}
                children={
                  <BarChart
                    data={incentivesData?.incentivesPerCampaign?.general}
                    type="incentive"
                  />
                }
              />
            )}
            {campaignPerformanceOverTime?.campaignPerformanceOverTime.general
              ?.data && (
              <DashboardCard
                title={t('dashboard_labels.campaign_performance')}
                description={t('dashboard_labels.over_time')}
                children={
                  <LineChart
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={campaignPerformanceOverTime?.campaignPerformanceOverTime.general.data.map(
                      (row) => {
                        if (!row) return { label: '', data: [''] }
                        return {
                          label: t(
                            `dashboard_labels.performance_by_time.general.${row.label}`,
                          ),
                          data: row.data,
                        }
                      },
                    )}
                    labels={
                      campaignPerformanceOverTime?.campaignPerformanceOverTime.general.labels?.map(
                        (l) => (l && format(new Date(l), 'yyyy-MM-dd')) || '',
                      ) || []
                    }
                  />
                }
              />
            )}
            {incentivesData?.incentivesPerCampaign?.general && (
              <DashboardCard
                title={t('dashboard_labels.requested_incentives')}
                description={t('dashboard_labels.by_share')}
                children={
                  <DoughnutChart
                    data={incentivesData?.incentivesPerCampaign?.general}
                  />
                }
              />
            )}
          </div>
          {isReferral && (
            <>
              {' '}
              <h1 className="pt-16 text-2xl border-b border-ui1">
                {t('dashboard_labels.referral_campaign')}
              </h1>
              <div className="grid grid-cols-2 gap-6">
                {campaignPerformance?.campaignPerformance?.referral.length && (
                  <DashboardCard
                    title={t('dashboard_labels.campaign_overview')}
                    description={t(
                      'dashboard_labels.since_the_beginning_of_the_campaign',
                    )}
                    children={
                      <BarChart
                        data={generalToChart(
                          campaignPerformance?.campaignPerformance?.referral,
                        )}
                        type="campaign"
                      />
                    }
                  />
                )}
                {incentivesData?.incentivesPerCampaign?.referral && (
                  <DashboardCard
                    title={t('dashboard_labels.requested_incentives')}
                    description={t('dashboard_labels.by_type')}
                    children={
                      <BarChart
                        data={incentivesData?.incentivesPerCampaign?.referral}
                        type="incentive"
                      />
                    }
                  />
                )}
                {campaignPerformanceOverTime?.campaignPerformanceOverTime
                  .referral?.data && (
                  <DashboardCard
                    title={t('dashboard_labels.campaign_performance')}
                    description={t('dashboard_labels.over_time')}
                    children={
                      <LineChart
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        data={campaignPerformanceOverTime?.campaignPerformanceOverTime.referral.data.map(
                          (row) => {
                            if (!row) return { label: '', data: [''] }
                            return {
                              label: t(
                                `dashboard_labels.performance_by_time.referral.${row.label}`,
                              ),
                              data: row.data,
                            }
                          },
                        )}
                        labels={
                          campaignPerformanceOverTime?.campaignPerformanceOverTime.referral.labels?.map(
                            (l) =>
                              (l && format(new Date(l), 'yyyy-MM-dd')) || '',
                          ) || []
                        }
                      />
                    }
                  />
                )}
                {incentivesData?.incentivesPerCampaign?.referral && (
                  <DashboardCard
                    title={t('dashboard_labels.requested_incentives')}
                    description={t('dashboard_labels.by_share')}
                    children={
                      <DoughnutChart
                        data={incentivesData?.incentivesPerCampaign?.referral}
                      />
                    }
                  />
                )}
              </div>
            </>
          )}
          {hasHappinessCheck && (
            <div className="pt-16">
              {happinessCheckCount?.endUsersHappinessCheck && (
                <DashboardCard
                  title={t('dashboard_labels.happiness_check')}
                  children={
                    <StackedBarChart
                      data={happinessCheckCount?.endUsersHappinessCheck}
                    />
                  }
                />
              )}
            </div>
          )}
          {hasHappinessCheck && (
            <div className="pt-16">
              {happinessCheckCount?.endUsersHappinessCheck && (
                <DashboardCard
                  title={'Incentive Codes Used'}
                  children={<BarChartHorizontal data={getVoucherStatistics} />}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

function generalToChart(
  data: { action: string; count: number }[],
  totalEndUsers?: number,
) {
  if (!data) return []
  const labels: Record<string, { order: number; label: string }> = {
    reached: {
      order: 1,
      label: t('dashboard_labels.overview.scanned_qr_codes'),
    },
    questionnaireStarted: {
      order: 2,
      label: t('dashboard_labels.overview.questionnaire_started'),
    },
    questionnaireCompleted: {
      order: 3,
      label: t('dashboard_labels.overview.questionnaire_completed'),
    },
    incentiveRequested: {
      order: 4,
      label: t('dashboard_labels.overview.incentives_requested'),
    },
  }

  const referralLabels: Record<string, { order: number; label: string }> = {
    reached: {
      order: 1,
      label: t('dashboard_labels.overview.referral_link_opens'),
    },
    questionnaireStarted: {
      order: 2,
      label: t('dashboard_labels.overview.questionnaire_started'),
    },
    questionnaireCompleted: {
      order: 3,
      label: t('dashboard_labels.overview.questionnaire_completed'),
    },
    incentiveRequested: {
      order: 4,
      label: t('dashboard_labels.overview.incentives_requested'),
    },
  }

  if (!totalEndUsers) {
    return [...data]
      .sort(
        (
          a: { action: string; count: number },
          b: { action: string; count: number },
        ) =>
          referralLabels[a?.action]?.order - referralLabels[b?.action]?.order,
      )
      .map((d: { action: string; count: number }) => ({
        label: referralLabels?.[d?.action]?.label,
        data: d?.count?.toString(),
      }))
  } else {
    return [
      {
        label: t('dashboard_labels.overview.campaign_reached'),
        data: totalEndUsers?.toString(),
      },
      ...[...data]
        .sort(
          (
            a: { action: string; count: number },
            b: { action: string; count: number },
          ) => labels?.[a?.action]?.order - labels?.[b?.action]?.order,
        )
        .map((d: { action: string; count: number }) => ({
          label: labels?.[d?.action]?.label,
          data: d?.count?.toString(),
        })),
    ]
  }
}
